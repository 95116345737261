import React, { useState } from "react";

export const Image = ({ title, largeImage, smallImage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <div onClick={openModal}>
          {/* <div className="hover-text">
            <h4>{title}</h4>
          </div> */}
          <img src={smallImage} className="img-responsive" alt={title} />
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={largeImage} alt={title} />
            <div className="caption">{title}</div>
          </div>
        </div>
      )}

    </div>
  );
};
