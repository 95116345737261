import React from 'react';

const WhatsappButton = () => {
  return (
    <a
    href="https://api.whatsapp.com/send?phone=5538991092826"
    target="_blank"
    rel="noopener noreferrer"
    className="whatsapp-icon"
  >
    <div className="whatsapp-button">
      <div className="whatsapp-message">
        Faça agora o seu pedido
      </div>
     
        <img src="/img/whatsapp/icon.png" alt="WhatsApp" className="whatsapp-image" />
      
    </div>
    </a>
  );
};

export default WhatsappButton;
